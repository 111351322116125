import React from 'react';
import { graphql, Link } from "gatsby"
import { compose } from 'recompose';

import Layout from '../components/layout';
import Navigation from '../components/Navigation';
import {
  AuthUserContext,
  withAuthorization,
} from '../components/Session';
import * as ROLES from '../constants/roles';
import HeadData from "../data/HeadData"

export const HomePageQuery = graphql`
  query dashboardQuery {
    allForms {
      edges {
        node {
          company
          id
        }
      }
    }
  }
`

const HomePageBase = ({ forms }) => (
  <div>
    <Navigation />

    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold leading-tight text-gray-900">
          Select Company
        </h1>
      </div>
    </header>

    <main>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="grid grid-cols-4 sm:grid-cols-1 gap-6 px-4 py-6 sm:px-0">
          {forms.map(form => {
            return (
              <AuthUserContext.Consumer>
                {authUser => (
                  authUser.roles[ROLES.ADMIN]
                  ? <Link to={`/${form.node.id}`} key={form.node.id}>
                      <button className='w-full flex justify-center py-4 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out'>
                        {form.node.company}
                      </button>
                    </Link>
                  : <>
                      {authUser.company === form.node.company
                      ? <Link to={`/${form.node.id}`} key={form.node.id}>
                          <button className='w-full flex justify-center py-4 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out'>
                            {form.node.company}
                          </button>
                        </Link>
                      : null}

                      {authUser.companyTwo === form.node.company
                      ? <Link to={`/${form.node.id}`} key={form.node.id}>
                          <button className='w-full flex justify-center py-4 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out'>
                            {form.node.company}
                          </button>
                        </Link>
                      : null}

                      {authUser.companyThree === form.node.company
                      ? <Link to={`/${form.node.id}`} key={form.node.id}>
                          <button className='w-full flex justify-center py-4 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out'>
                            {form.node.company}
                          </button>
                        </Link>
                      : null}
                    </>
                  )
                }
              </AuthUserContext.Consumer>
            )
          })}
        </div>
      </div>
    </main>
  </div>
);

const condition = authUser => !!authUser;

const HomePage = compose(
  withAuthorization(condition),
)(HomePageBase);

export default ({ data: { allForms } }) => (
  <Layout>
    <HeadData title='Dashboard' />
    <HomePage forms={allForms.edges} />
  </Layout>
)
